<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="goodMessage">
                    <div class="flex align_center justify_center item">
                        <div>
                            <previewImage :thumWidth="60" :thumHeight="60" :src="row.goodPic"></previewImage>
                        </div>
                        <div class="flex column align_start justify_center ml_10">
                            <div class="break_all">{{ row.goodName }}</div>
                            <div class="break_all">{{ row.goodSpec }}</div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="classType">{{ getClassTypeName(row.classList) }}</template>
                <template slot-scope="{ row }" slot="totalPrice">{{ row.totalPrice | cny }}</template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { createId } from '@/lib/util';
import previewImage from '@/components/previewImage';
// import { reqBannerList } from '@/lib/request/auth';

export default {
    name: 'accountList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        return {
            searchs: [
                { id: hm.createId(), label: '关键词', placeholder: '输入商品名称', type: 'input', bind: 'goodName' },
                {
                    id: hm.createId(),
                    label: '退款原因',
                    placeholder: '请选择',
                    type: 'select',
                    bind: 'cause',
                    list: [
                        { id: '1', title: '错拍' },
                        { id: '2', title: '误拍' },
                    ],
                },
            ],
            dataColumn: [
                { slot: 'goodMessage', title: '商品描述', minWidth: 250 },
                { slot: 'classType', title: '分类', minWidth: 170 },
                { key: 'realNum', title: '支付人数', minWidth: 120 },
                { key: 'orderNum', title: '支付订单数', minWidth: 120 },
                { slot: 'totalPrice', title: '订单金额', minWidth: 120 },
                { key: 'address', title: '地址', minWidth: 150 },
                { key: 'xiaoliang', title: '销量', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getClassTypeName(classList = {}) {
            return classList.title + '-' + (classList.children || {}).title;
        },
        //获取列表数据
        getList() {
            console.log(JSON.stringify(this.params, null, '\t'));

            this.dataList = Array.from({ length: 15 }, (v, k) => ({
                id: createId(),
                goodPic: 'http://hd.qilang.cn/public/uploads/temp/659be1798fa483944.png',
                goodName: 'MY123现代简约吸' + k,
                classList: {
                    pid: 0,
                    id: '12',
                    title: '营养靓汤',
                    children: {
                        pid: '12',
                        id: '13',
                        title: '营养靓汤1.0',
                    },
                },
                realNum: ~~(Math.random() * 1000),
                orderNum: ~~(Math.random() * 10000),
                totalPrice: Math.random() * 50000,
                address: 'xxxx省xxxx市xxx区 xxxxx街道xxxxxx号',
                xiaoliang: ~~(Math.random() * 50000),
            }));
            // this.showAjaxLoading();
            // reqBannerList(this.params)
            //     .then(res => {
            //         this.dataInjectDataList(res);
            //     })
            //     .finally(() => {
            //         this.hideAjaxLoading();
            //     });
        },
    },
};
</script>
